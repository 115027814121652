<template>
	<div class="info-container" :class="{ 'tooltip-criteria': label == 'ellipsis' }" @mouseover="showTooltip()"
		v-if="!tooltipDisbaled">
		<span class="tooltip-label" v-bind:class="{ 'badge bg-none': label == 'ellipsis', 'sup': sup }">
			<i class="fa-sharp fa-solid fa-circle-info" v-if="label == 'icon'"></i>
			<i class="fa-regular fa-ellipsis" v-else-if="label == 'ellipsis'"></i>
			<a :href="linkto" v-else-if="label == 'Analysis'">{{ label }}</a>
			<span v-else>{{ label }}</span>
		</span>

		<div class="info-tooltip criteria" :id="tooltip" v-if="label == 'ellipsis'">
			<span class="icon-bar top"></span>
			<div class="content">
				<span v-for="(element, i) in items" :key="i" class="criteria-span">{{ element.title ? element.title :
					element.label }}</span>
			</div>
			<span class="icon-bar bottom d-none"></span>
		</div>

		<div class="info-tooltip" :id="tooltip" v-else>
			<span class="icon-bar top"></span>
			<div class="content" v-html="content"></div>
			<span class="icon-bar bottom d-none"></span>
		</div>
	</div>
</template>
<script>
import CommonJS from "@vuedir/js/CommonJS.js";
import $ from "jquery";
export default {
	data() {
		return {
			currentTooltip: {},
			tooltipDisbaled: false,
			wrapper: "div",
		};
	},

	props: {
		label: {
			type: String,
			required: true,
		},
		content: {
			type: String,
			required: false,
		},
		items: {
			type: Array,
			required: false,
		},
		tooltip: {
			type: String,
			required: false,
		},
		linkto: {
			type: String,
			required: false,
		},
		sup: {
			type: Boolean,
			required: false
		}
	},

	computed: {
		dynamicContent() {
			let str = "";
			this.items.forEach((element) => {
				let label = "";
				if (element.label) {
					label = element.label;
				} else if (element.title) {
					label = element.title;
				}
				str += `${label}</br>`;
			});

			return str;
		},
	},

	methods: {
		showTooltip() {
			let label = $("#" + this.tooltip).parent();
			let tooltip = $("#" + this.tooltip);

			if (this.currentTooltip == label) {
				return;
			}

			this.currentTooltip = label;

			tooltip.css({
				top: 0,
				height: "auto",
				"margin-top": !tooltip.hasClass("criteria")
					? label.height() + "px"
					: "12px",
			});
			tooltip.find('div.content').removeClass("overflow-scroll");
			tooltip.find('.icon-bar.top').removeClass("d-none");
			tooltip.find('.icon-bar.bottom').addClass("d-none");

			let height = $(tooltip).outerHeight(),
				top = $(tooltip).offset().top,
				scroll = $(window).scrollTop(),
				bottom = $(tooltip).offset().top + height,
				endWindow = $(window).height() + $(window).scrollTop(),
				startWindow = top - scroll;

			if (bottom > endWindow && height < startWindow) {
				tooltip.css({
					top: `-${$(tooltip).outerHeight() + 25}px`,
				});
				tooltip.find('.icon-bar.top').addClass("d-none");
				tooltip.find('.icon-bar.bottom').removeClass("d-none");
			} else if (bottom > endWindow && height > startWindow) {
				tooltip.css({
					height: `${endWindow - top - 50}px`,
				});
				tooltip.find('.content').addClass("overflow-scroll");
				tooltip.find('.icon-bar.top').removeClass("d-none");
				tooltip.find('.icon-bar.bottom').addClass("d-none");
			}

			$(this.currentTooltip).removeClass(["start", "end"])
			$(this.currentTooltip).find(".icon-bar").removeClass(["start", "end"]);

			let left = tooltip.offset().left,
				right = tooltip.offset().left + tooltip.outerWidth();

			if (right > $(window).width()) {
				$(this.currentTooltip).addClass("end");
				$(this.currentTooltip).find(".icon-bar").addClass("end");
			}
		},
		formattedComments(text) {
			return CommonJS.formattedComments(text);
		},
	},
	mounted() {
		if (this.sup) {
			this.wrapper = "sup"
		}
		if (this.content == "None") this.tooltipDisbaled = true;
	},
};
</script>
<style lang="scss" scoped>
@import "../../scss/modules/_variables.scss";

.info-container {
	display: inline-flex;
	justify-content: center;
	color: $brand-color-secondary;
	position: relative;

	&.tooltip-criteria {
		order: 3;
		margin-left: auto;
	}

	&:hover {
		.info-tooltip {
			display: inline-flex;
		}
	}

	.badge.bg-none {
		display: inline-block;
		order: 3;
		margin-right: auto !important;
		background: none;
		padding: 0;
		color: #000 !important;
	}

	.info-tooltip {
		display: none;
		width: 300px;
		position: absolute;
		flex-direction: column;
		justify-content: center;

		.content {
			width: 100%;
			padding: 15px;
			z-index: 9999;
			min-height: 60px;
			border-radius: 8px;
			color: $base-color;
			background: $share-bg;
			justify-content: center;
			flex-wrap: wrap;

			.criteria-span {
				display: block;
				text-align: left;
				margin-bottom: 10px;
				width: 100%;
			}
		}

		.icon-bar {
			width: 100%;
			display: flex;
			justify-content: center;

			&::before {
				content: "";
				width: 0;
				height: 0;
				position: absolute;
				pointer-events: none;
				border: solid transparent;
				border-right-color: $share-bg;
				border-width: 8px;
			}

			&.top {
				&::before {
					transform: rotate(90deg);
					top: -14px;
					bottom: 0;
				}
			}

			&.bottom {
				&::before {
					transform: rotate(270deg);
					top: unset;
					bottom: -14px;
				}
			}

			&.start {
				justify-content: flex-start;
			}

			&.end {
				justify-content: flex-end;
			}
		}
	}
	&.end {
			justify-content: flex-end;

			.content {
				border-top-right-radius: 0;
			}
		}

		&.start {
			justify-content: flex-start;

			.content {
				border-top-left-radius: 0;
			}
		}
}</style>
